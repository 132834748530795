<template>
  <common-trs-page-container>
    <template v-slot:page-content>
      <common-trs-card class="pa-2 pb-0 mb-2">
        <v-card-title>
          Edit Financing Round
        </v-card-title>
        <validation-observer
          ref="observer"
          v-slot="{ valid }"
          class="ml-auto"
        >
          <v-row class="pa-5">
            <v-col>
              <scenario-financing-form
                v-model="roundData"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-space-between pa-5">
            <v-col>
              <common-trs-btn
                type="secondary"
                medium
                class="mr-2"
                text
                :to="{name: 'Scenarios'}"
              >
                Cancel
              </common-trs-btn>
            </v-col>
            <v-col class="text-right">
              <common-trs-btn
                type="primary"
                medium
                class="white--text"
                text
                :disabled="!valid"
                :loading="loading"
                @click="save"
              >
                Save
              </common-trs-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>

<script>
  import ScenarioFinancingForm from '@/components/common/captable/components/forms/ScenarioFinancingForm'
  import * as captableService from '@/components/common/captable/services/captable'

  export default {
    name: 'EditScenarioFinancingRound',

    components: {
      ScenarioFinancingForm,
    },

    data: () => ({
      loading: false,
      scenariosData: {},
      roundData: {},
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      scenarioId () {
        return this.$route.query.id
      },
      roundName () {
        return this.$route.query.round
      },
    },

    mounted () {
      this.loadExistingScenario()
    },

    methods: {
      async loadExistingScenario () {
        try {
          const resp = await captableService.getScenariosById(this.captableId, this.scenarioId)
          this.scenariosData = resp.data
          this.roundData = this.getRoundData()
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },
      async save () {
        this.loading = true
        this.createScenarioData()
        try {
          await captableService.updateScenarioById(this.captableId, this.scenarioId, this.scenariosData)
          this.loading = false
          this.$store.dispatch('app/message', { text: 'Financing round added to scenario successfully.' })
          this.$router.push({ name: 'Scenarios' })
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to add financing round to scenario. Please try again later.',
          })
        }
      },
      clearNullData (element) {
        Object.keys(element).forEach((key) => {
          if (element[key] === null || element[key] === undefined) {
            delete element[key]
          }
        })
      },
      createScenarioData () {
        this.scenariosData.scenario_rounds.map((element) => {
          return this.clearNullData(element)
        })

        this.scenariosData.scenario_rounds.map((element) => {
          return this.clearNullData(element.liquidation_preference)
        })

        const index = this.scenariosData.scenario_rounds.findIndex((round) => {
          return round.name === this.roundName
        })
        this.scenariosData.scenario_rounds[index] = this.roundData
      },
      getRoundData () {
        return this.scenariosData.scenario_rounds.find((round) => {
          return round.name === this.roundName
        })
      },
    },
  }
</script>
